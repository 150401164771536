import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import HtmlRenderer from '../utility/HtmlRenderer';
import { getFormatedDate } from '../../js/utility';

const Details = ({userVerification}) => {
    const { name, id } = useParams();
    const [evento, setEvento] = useState([]);
    const currentDate = new Date();

    useEffect(() => {
        const url = `${process.env.REACT_APP_API_ENDPOINT}/server/api/v1/eventos/index.php?id=${id}`;
        const fetchData = async () => {            
            try {
                const response = await axios.get(url);
                const eventoData = response.data;
                if (eventoData.length === 0) { window.location.href = ('/') }
                setEvento(eventoData[0]);
                if (name !== eventoData[0].title) window.location.href = ('/eventos');
            } catch (error) {
                console.error("Error en la solicitud:", error);
            }
        };
        fetchData();
    }, [id, name]);

    const handleDelete = async () => {
        const confirmed = window.confirm("¿Estás seguro de que quieres eliminar este evento? Esta acción no se podrá deshacer.");

        if (!confirmed) return;

        try {
            const url = `${process.env.REACT_APP_API_ENDPOINT}/server/api/v1/eventos/index.php?delete=${id}`;
            const response = await axios.delete(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                alert("Evento eliminado exitosamente");
                window.location.href = ('/eventos');
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
        }
    };

    return (
        <div>
            <section
                id="section_1"
                className="hero-section evento hero-50 d-flex justify-content-center align-items-center"
                style={{ backgroundImage: `url('${process.env.REACT_APP_API_ENDPOINT}/images/eventos/${evento.image}')` }}
            >

                <div className="section-overlay" />

                <svg viewBox="0 0 1962 178" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path fill="#7F8442" d="M 0 114 C 118.5 114 118.5 167 237 167 L 237 167 L 237 0 L 0 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 236 167 C 373 167 373 128 510 128 L 510 128 L 510 0 L 236 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 509 128 C 607 128 607 153 705 153 L 705 153 L 705 0 L 509 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 704 153 C 812 153 812 113 920 113 L 920 113 L 920 0 L 704 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 919 113 C 1048.5 113 1048.5 148 1178 148 L 1178 148 L 1178 0 L 919 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 1177 148 C 1359.5 148 1359.5 129 1542 129 L 1542 129 L 1542 0 L 1177 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 1541 129 C 1751.5 129 1751.5 138 1962 138 L 1962 138 L 1962 0 L 1541 0 Z" strokeWidth="0"/></svg>

                <div className="container">
                    <div className="row">

                        <div className="col-12">

                            <h1 className="text-white mb-3 pb-2 display-3 fw-bolder">{evento.title}</h1>
                            <div className="custom-btn-group">
                                {
                                    userVerification ?
                                        <div className="d-flex">
                                            <a href={`/eventos`} className="btn custom-btn smoothscroll me-3">{`Volver a los eventos`}</a>
                                            <a href={`/evento/editar/${name}/${id}`} className="btn custom-btn custom-border-btn text-white smoothscroll ms-auto me-3">Editar</a>
                                            <button onClick={handleDelete} className="btn custom-btn custom-border-btn-danger text-white smoothscroll me-3">Eliminar</button>
                                        </div>
                                    : <a href={`/eventos`} className="btn custom-btn smoothscroll me-3">{`< Volver a los eventos`}</a>
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <svg viewBox="0 0 1962 178" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path fill="#ffffff" d="M 0 114 C 118.5 114 118.5 167 237 167 L 237 167 L 237 0 L 0 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 236 167 C 373 167 373 128 510 128 L 510 128 L 510 0 L 236 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 509 128 C 607 128 607 153 705 153 L 705 153 L 705 0 L 509 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 704 153 C 812 153 812 113 920 113 L 920 113 L 920 0 L 704 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 919 113 C 1048.5 113 1048.5 148 1178 148 L 1178 148 L 1178 0 L 919 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 1177 148 C 1359.5 148 1359.5 129 1542 129 L 1542 129 L 1542 0 L 1177 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 1541 129 C 1751.5 129 1751.5 138 1962 138 L 1962 138 L 1962 0 L 1541 0 Z" strokeWidth="0"/></svg>
            </section>


            <section className="events-section events-detail-section section-padding" id="section_2">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="custom-block-info pt-0">
                                {
                                    evento.soldOut === 1 && new Date(evento.date) > currentDate ?
                                        <div className="custom-block-date-wrap bg-danger py-3 mb-5">
                                            <h3 className='mb-0'><strong className="text-white text-uppercase">No hay más cupos disponibles</strong></h3>
                                        </div>
                                        : ''
                                }
                                {
                                    new Date(evento.date) < currentDate ?
                                        <div className="custom-block-date-wrap py-3 mb-5">
                                            <h3 className='mb-0'><strong className="text-white">Este evento ya se llevó a cabo el dia: {getFormatedDate(evento.date)}.</strong></h3>
                                        </div>
                                        : ''
                                }
                                {evento.about && evento.about.trim() !== "" && (
                                    <>
                                        <h1 className="mb-3">Sobre el evento</h1>
                                        <HtmlRenderer html={evento.about} />
                                    </>
                                )}

                                {evento.objetives && evento.objetives.trim() !== "" && (
                                    <>
                                        <h1 className="mt-5 mb-3">Objetivos</h1>
                                        <HtmlRenderer html={evento.objetives} />
                                    </>
                                )}

                                {evento.planification && evento.planification.trim() !== "" && (
                                    <>
                                        <h1 className="mt-5 mb-3">Planificacion</h1>
                                        <HtmlRenderer html={evento.planification} />
                                    </>
                                )}

                                {evento.including && evento.including.trim() !== "" && (
                                    <>
                                        <h1 className="mt-5 mb-3">Materiales e inversión</h1>
                                        <HtmlRenderer html={evento.including} />
                                    </>
                                )}

                                {evento.important && evento.important.trim() !== "" && (
                                    <>
                                        <h1 className="mt-5 mb-3">Importante</h1>
                                        <HtmlRenderer html={evento.important} />
                                    </>
                                )}
                                                                
                                <div className={`events-detail-info row my-5 custom-block-image-wrap ${evento.soldOut === 1 && new Date(evento.date) > currentDate ? 'pt-4' : 'py-5'}`}>
                                    <div className="col-lg-12 col-12">
                                        <h4 className="mb-3">Detalles del evento</h4>
                                    </div>

                                    <div className="col-lg-3 col-12 mb-3">
                                        <span className="custom-block-span">Fecha:</span>

                                        <p className="mb-0">{getFormatedDate(evento.date)}</p>
                                    </div>

                                    <div className="col-lg-3 col-12 mb-3">
                                        <span className="custom-block-span">Ubicacion:</span>

                                        <p className="mb-0">{evento.location}</p>
                                    </div>

                                    <div className="col-lg-3 col-12 mb-3">
                                        <span className="custom-block-span">Cupos:</span>

                                        <p className="mb-0">{evento.people}</p>
                                    </div>
                                    <div className="col-lg-3 col-12 mb-3">
                                        <span className="custom-block-span">Precio:</span>

                                        <p className="mb-0">${evento.cost}</p>
                                    </div>
                                    {
                                        evento.soldOut === 1 && new Date(evento.date) > currentDate ?
                                            <div className="custom-block-date-wrap bg-danger mt-3">
                                                <strong className="text-white text-uppercase">No hay más cupos disponibles</strong>
                                            </div>
                                            : ''
                                    }
                                </div>

                                <div className="contact-info mt-5 text-center">
                                    <div className="contact-info-item w-auto">
                                        <div className="contact-info-body">

                                            <p className="mt-2 mb-1">
                                                <i className="bi-whatsapp text-white me-2"/>
                                                <a href="https://wa.me/59899932721" target="_blank" className="contact-link" rel="noreferrer">
                                                099 932 721
                                                </a>
                                            </p>

                                            <p className="mb-0 d-flex justify-content-center">
                                                <i className="bi-envelope-fill text-white me-2"/>
                                                <a href="mailto:educacioncanina@sofiaciliano.com.uy" target="_blank" className="contact-link" rel="noreferrer">
                                                    educacioncanina@sofiaciliano.com.uy
                                                </a>
                                            </p>
                                        </div>

                                        <div className="contact-info-footer text-white">
                                                <strong>Ciudad de la Costa, Uruguay</strong>
                                                <br />
                                                <strong>Montevideo, Uruguay</strong>
                                        </div>
                                    </div>

                                    <img src="/images/perro.svg" className="img-fluid" width="500" alt=""/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
};

export default Details;