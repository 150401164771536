import React from 'react';

const NotFound = () => {

    return (
        <div>
            <section className="hero-section evento hero-50 d-flex justify-content-center align-items-center" id="section_1">

                <div className="section-overlay" />

                <svg viewBox="0 0 1962 178" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path fill="#7F8442" d="M 0 114 C 118.5 114 118.5 167 237 167 L 237 167 L 237 0 L 0 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 236 167 C 373 167 373 128 510 128 L 510 128 L 510 0 L 236 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 509 128 C 607 128 607 153 705 153 L 705 153 L 705 0 L 509 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 704 153 C 812 153 812 113 920 113 L 920 113 L 920 0 L 704 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 919 113 C 1048.5 113 1048.5 148 1178 148 L 1178 148 L 1178 0 L 919 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 1177 148 C 1359.5 148 1359.5 129 1542 129 L 1542 129 L 1542 0 L 1177 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 1541 129 C 1751.5 129 1751.5 138 1962 138 L 1962 138 L 1962 0 L 1541 0 Z" strokeWidth="0"/></svg>

                <div className="container">
                    <div className="row">

                        <div className="col-lg-8 col-12">

                            <h1 className="text-white mb-4 pb-2">404 - Page Not found</h1>
                        </div>

                    </div>
                </div>
                <svg viewBox="0 0 1962 178" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path fill="#ffffff" d="M 0 114 C 118.5 114 118.5 167 237 167 L 237 167 L 237 0 L 0 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 236 167 C 373 167 373 128 510 128 L 510 128 L 510 0 L 236 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 509 128 C 607 128 607 153 705 153 L 705 153 L 705 0 L 509 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 704 153 C 812 153 812 113 920 113 L 920 113 L 920 0 L 704 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 919 113 C 1048.5 113 1048.5 148 1178 148 L 1178 148 L 1178 0 L 919 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 1177 148 C 1359.5 148 1359.5 129 1542 129 L 1542 129 L 1542 0 L 1177 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 1541 129 C 1751.5 129 1751.5 138 1962 138 L 1962 138 L 1962 0 L 1541 0 Z" strokeWidth="0"/></svg>
            </section>


            <section className="events-section events-detail-section section-padding" id="section_2">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mx-auto">
                            <h2>Ups, lo sentimos mucho, no pudimos encontrar esta pagina.</h2>
                            <h3 className='mt-5 mb-4'>Que le gustaria hacer?</h3>
                            <h4 className='ms-5'>
                                <a href="/eventos" className="link smoothscroll me-3">{`► Ver listado de eventos >`}</a>
                            </h4>
                            <h4 className='ms-5'>
                                <a href="/" className="link smoothscroll me-3">{`► Volver al inicio >`}</a>
                            </h4>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default NotFound;