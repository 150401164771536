import React from 'react';
import { getFormatedDate } from '../../js/utility'
import HtmlRenderer from '../utility/HtmlRenderer';

const events = ({events, page}) => {
    return (
        <section className={`events-section section-bg ${page === 'eventList' ? 'section-padding' : ''}`} id="proximos-eventos">
            <div className="container">
                <div className="row">

                    <div className="col-md-9 col-12 mt-4">
                        <h2 className="mb-lg-3">Próximos Eventos</h2>
                    </div>
                    {
                        page === 'homepage' ?
                            <div className="col-md-3 col-12 mt-4 mt-md-2">
                                    <a href="/eventos" className="btn custom-btn w-100">Ver todos los eventos</a>
                            </div>
                            : ''
                    }

                    {
                        events.length > 0 ?
                        events.map((item, key) => (

                        <div key={item.id} id={item.id} className={`row custom-block mb-3 ${key % 2 !== 0 ? 'custom-block-bg' : ''}`}>
                                <div className="col-lg-2 col-md-4 col-12 order-2 order-md-0 order-lg-0">
                                    <div className="custom-block-date-wrap d-flex d-lg-block d-md-block align-items-center mt-3 mt-lg-0 mt-md-0">
                                        <h6 className="custom-block-date mb-lg-1 mb-0 me-3 me-lg-0 me-md-0">{getFormatedDate(item.date, 'day')}</h6>
                                        
                                        <strong className="text-white">{getFormatedDate(item.date, 'month')} {getFormatedDate(item.date, 'year')}</strong>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-8 col-12 order-1 order-lg-0">
                                    <div className="custom-block-image-wrap d-flex">
                                        <div className="image-container-event-list">
                                            <a href={`/evento/${item.title}/${item.id}`}>
                                                <img src={`${process.env.REACT_APP_API_ENDPOINT}/images/eventos/${item.image}`} className="custom-block-image img-fluid" alt="" />

                                                <i className="custom-block-icon bi-link" />
                                            </a>
                                            {
                                                item.soldOut === 1 ?
                                                    <div className="custom-block-date-wrap bg-danger">
                                                        <strong className="text-white text-uppercase">No hay más cupos disponibles</strong>
                                                    </div>
                                                    : ''
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-12 order-3 order-lg-0">
                                    <div className="custom-block-info mt-2 mt-lg-0">
                                        <a href={`/evento/${item.title}/${item.id}`} className="events-title mb-3">{item.title}</a>
                                        

                                        <div className="mb-0 truncate">
                                            <HtmlRenderer
                                                html={item.about}
                                            />
                                        </div>

                                        <div className="d-flex flex-wrap justify-content-between border-top mt-4 pt-3">

                                            <div className="mb-4 mb-lg-0">
                                                <div className="d-flex flex-wrap align-items-center mb-1">
                                                    <span className="custom-block-span">Ubicacion:</span>

                                                    <p className="mb-0">{item.location}</p>
                                                </div>

                                                <div className="d-flex flex-wrap align-items-center">
                                                    <span className="custom-block-span">Cupos:</span>

                                                    <p className="mb-0">{item.people}</p>
                                                </div>

                                                <div className="d-flex flex-wrap align-items-center">
                                                    <span className="custom-block-span">Precio:</span>

                                                    <p className="mb-0">${item.cost}</p>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center ms-lg-auto">
                                                <a href={`/evento/${item.title}/${item.id}`} className="btn custom-btn">Ver mas</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        : 
                        <div className="col-lg-12 col-12 text-center text-md-start my-4">
                            <h3 className="mb-lg-5 mb-4">No se encontraron próximos eventos</h3>
                        </div>
                    }
                </div>
            </div>
        </section>

    );
};

export default events;