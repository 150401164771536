import React, { useState } from 'react';
import axios from 'axios';
import Modal from '../utility/Modal.jsx';
import InputWithValidation from '../utility/InputWithValidation';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';

const CreateUserModal = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        name: '',
        lastName: '',
        email: ''
    });

    const form = useRef();
    const [formSent, setFormSent] = useState({success: false, error: false});

    const { register, handleSubmit, formState: { errors } } = useForm();

    const generateLoginToken = () => {
        const generateRandomToken = (length) => {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()-_=+[]{}|;:,.<>?';
            let token = '';
            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                token += characters.charAt(randomIndex);
            }
            return token;
        };
        
        const tokenLength = Math.floor(Math.random() * 11) + 70;
        return generateRandomToken(tokenLength);
    };

    const handleChange = event => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleCreateUser = async () => {
        try {
            const loginToken = generateLoginToken(); // Genera el login_token
            const response = await axios.post(
                `${process.env.REACT_APP_API_ENDPOINT}/server/api/v1/login/index.php`,
                {
                    ...formData,
                    login_token: loginToken // Agrega el login_token a los datos enviados
                },
                { params: { action: 'createUser'} });

            if (response.data.success) {
                setFormSent({success: true, error: false});
                setFormData({ username: '', password: '', name: '', lastName: '', email: '' }); // Limpiar campos
                setTimeout(() => {
                    window.location.href = '/';
                }, 1000);
            } else {
                setFormSent({success: false, error: true});
                throw new Error(response.data.message)
            }
        } catch (error) {
            console.error('Error al crear el usuario:', error);
        }
    };

    const inputValidations = {
        username: {
            label: 'Usuario',
            validations: {
                required: { value: true, message: 'Usuario requerido.' },
                maxLength: { value: 50, message: 'El texto no debe exceder los 50 caracteres.' },
            }
        },
        password: {
            label: 'Contraseña',
            validations: {
                required: { value: true, message: 'Contraseña requerida.' },
                maxLength: { value: 100, message: 'El texto no debe exceder los 100 caracteres.' },
            }
        },
        name: {
            label: 'Nombre',
            validations: {
                required: { value: true, message: 'Nombre requerido.' },
                maxLength: { value: 50, message: 'El texto no debe exceder los 50 caracteres.' },
                pattern: { value: /^[A-Za-z_ ]+$/i, message: 'El nombre no puede contener numeros.' },
            }
        },
        lastName: {
            label: 'Apellido',
            validations: {
                required: { value: true, message: 'Apellido requerido.' },
                maxLength: { value: 50, message: 'El texto no debe exceder los 50 caracteres.' },
                pattern: { value: /^[A-Za-z_ ]+$/i, message: 'El apellido no puede contener numeros.' },
            }
        },
        email: {
            label: 'Email',
            validations: {
                required: { value: true, message: 'Email requerido.' },
                maxLength: { value: 100, message: 'El texto no debe exceder los 100 caracteres.' },
                pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Ingresa un email valido.' },
            }
        },
    };

    return (
        <Modal
            title="Registro de usuario"
            onConfirm={handleSubmit(handleCreateUser)}
            onClose={() => { setFormSent() }}
            id="createUserModal"
            label="createUserModalLabel"
        >
            <form ref={form} className="custom-form contact-form">
                <InputWithValidation
                    type="text"
                    name="username"
                    placeholder={inputValidations.username.label}
                    className={errors.username ? 'form-control mb-0 is-invalid' : 'form-control'}
                    register={register}
                    validation={inputValidations.username.validations}
                    value={formData.username}
                    onChange={handleChange}
                    errors={errors}
                    inputValidations={inputValidations}
                />
                <InputWithValidation
                    type="password"
                    name="password"
                    placeholder={inputValidations.password.label}
                    className={errors.password ? 'form-control mb-0 is-invalid' : 'form-control'}
                    register={register}
                    validation={inputValidations.password.validations}
                    value={formData.password}
                    onChange={handleChange}
                    errors={errors}
                    inputValidations={inputValidations}
                />
                <InputWithValidation
                    type="text"
                    name="name"
                    placeholder={inputValidations.name.label}
                    className={errors.name ? 'form-control mb-0 is-invalid' : 'form-control'}
                    register={register}
                    validation={inputValidations.name.validations}
                    value={formData.name}
                    onChange={handleChange}
                    errors={errors}
                    inputValidations={inputValidations}
                />
                <InputWithValidation
                    type="text"
                    name="lastName"
                    placeholder={inputValidations.lastName.label}
                    className={errors.lastName ? 'form-control mb-0 is-invalid' : 'form-control'}
                    register={register}
                    validation={inputValidations.lastName.validations}
                    value={formData.lastName}
                    onChange={handleChange}
                    errors={errors}
                    inputValidations={inputValidations}
                />
                <InputWithValidation
                    type="email"
                    name="email"
                    placeholder={inputValidations.email.label}
                    className={errors.email ? 'form-control mb-0 is-invalid' : 'form-control'}
                    register={register}
                    validation={inputValidations.email.validations}
                    value={formData.email}
                    onChange={handleChange}
                    errors={errors}
                    inputValidations={inputValidations}
                />
            </form>
            {
                formSent.success &&
                !formSent.error && 
                <div className="form-success">
                    Usuario creado correctamente.
                </div>}
            {
                !formSent.success &&
                formSent.error && 
                <div className="form-error">
                    Se ha producido un error. Por favor, intenta nuevamente. <br />
                    Si el problema persiste, inténtalo de nuevo más tarde.
                </div>
            }
        </Modal>
    );
}

export default CreateUserModal;
