const About = () => {
    return (
        <section className="about-section section-padding" id="sobre_mi">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-12 text-center">
                        <h2 className="mb-lg-5 mb-4">Sobre mi</h2>
                    </div>

                    <div className="col-lg-5 col-12 ms-lg-5 me-auto mb-4 mb-lg-0">
                        <h3 className="mb-3">Sofia Ciliano</h3>

                        <p>Mi nombre es Sofía Ciliano y trabajo como educadora canina hace mas de 10 años.</p>
                        <p>
                            Entre mi constante formación desde 2011 destaco: <br />
                            - Tecnicatura en Gestión del Comportamiento Cognitivo Emocional, título otorgado por Educan, España. <br />
                            - Tecnicarura en enfermería y farmacia Veterinaria <br />
                            - Facultad de Veterinaria UdelaR (2do año cursado). 
                        </p>
                        <p>
                            Desde el amor que tengo hacia los perros, me dedico a colaborar con sus humanos para lograr una convivencia armónica y feliz, mediante técnicas adecuadas a cada situación, partiendo de la base de que nuestros perros son animales que sienten y piensan.
                        </p>
                        <p>
                            Utilizo métodos que favorecen y estimulan sus habilidades más desarrolladas sin separarlos de su esencia más pura y genuina, ser perros.
                        </p>
                    </div>

                    <div className="col-lg-6 col-12 mb-4 mb-lg-0 mb-md-0">
                        <div className="block h-75">
                            <div className="block-image-wrap d-flex justify-content-center h-100">
                                <img src="images/profile.jpg" className="block-image rounded-5 img-fluid" alt="Foto de perfil de Sofi"/>

                                <ul className="social-icon">
                                    <li className="social-icon-item">
                                        <a href="https://www.instagram.com/sofiacilianoeducanina/" target="_blank" rel="noreferrer">
                                            <i className="social-icon-link bi-instagram"/>
                                        </a>
                                    </li>

                                    <li className="social-icon-item">
                                        <a href="https://wa.me/59899932721" target="_blank" rel="noreferrer">
                                            <i className="social-icon-link bi-whatsapp"/>
                                        </a>
                                    </li>
                                    
                                    <li className="social-icon-item">
                                        <a href="https://www.linkedin.com/company/sof%C3%ADa-ciliano-educaci%C3%B3n-canina/" target="_blank" rel="noreferrer">
                                            <i className="social-icon-link bi-linkedin"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;