const Header = () => {
    return (
        <nav className="navbar navbar-expand-lg">                
            <div className="container">
                <a className="navbar-brand d-flex align-items-center" href="/">
                    <img src="/images/logos/logo.svg" className="navbar-brand-image img-fluid" alt="Sofia Ciliano Educación Canina"/>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-lg-auto">
                        <li className="nav-item">
                            <a className="nav-link click-scroll" href="/#inicio">Inicio</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link click-scroll" href="/#sobre_mi">Sobre mi</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link click-scroll" href="/#servicios">Servicios</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link click-scroll" href="/#galeria">Galería</a>
                        </li>
                        
                        <li className="nav-item">
                            <a className="nav-link click-scroll" href="/#próximos-eventos">Eventos</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link click-scroll" href="/#contacto">Contacto</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Header;