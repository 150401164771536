import Header from '../content/Header';
import Details from '../content/Details';
import Update from '../app/Update';
import Create from '../app/Create';
import Footer from '../content/Footer';
import NotFound from '../content/NotFound';
import BackToTopButton from '../utility/BackToTheTopButton';

const EventDetails = ({ userVerification, mode }) => {

    return (
        <main className="d-flex flex-column">
            <Header />
            {mode === 'view' ? (
                <Details userVerification={userVerification}/>
            ) : userVerification ? (
                mode === 'update' ? (
                    <Update userVerification={userVerification}/>
                ) : mode === 'create' ? (
                    <Create userVerification={userVerification}/>
                ) : (
                    <NotFound />
                )
            ) : (
                <NotFound />
            )}
            <Footer userVerification={userVerification}/>

            <BackToTopButton />
        </main>
    );
};

export default EventDetails;