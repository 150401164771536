import React from 'react';
import { getFormatedDate } from '../../js/utility'
import HtmlRenderer from '../utility/HtmlRenderer';

const events = ({events}) => {
    return (
        <section className="events-section" id="lastest-events">
            <div className="container">
                <div className="row justify-content-center">

                    <div className="col-lg-12 col-12">
                        <h2 className="mb-lg-5 mb-4">Ultimos eventos</h2>
                    </div>

                    {
                        events.length > 0 ?
                            events.map((item, key) => (
                            <div key={item.id} id={item.id} className="col-md-6 col-lg-4 col-12 mb-5">
                                <div className="custom-block-image-wrap d-flex justify-content-center">
                                    <div className="image-container-event-list">
                                        <a href={`/evento/${item.title}/${item.id}`}>
                                            <img src={`${process.env.REACT_APP_API_ENDPOINT}/images/eventos/${item.image}`} className="custom-block-image img-fluid" alt="" />

                                            <i className="custom-block-icon bi-link" />`
                                        </a>

                                        <div className="custom-block-date-wrap">
                                            <strong className="text-white">{getFormatedDate(item.date)}</strong>
                                        </div>
                                    </div>
                                </div>

                                <div className="custom-block-info">
                                    <a href={`/evento/${item.title}/${item.id}`} className="events-title mb-2">{item.title}</a>

                                    <div className="mb-0 truncate">
                                        <HtmlRenderer
                                            html={item.about}
                                        />
                                    </div>


                                    <div className="border-top mt-4 pt-3">
                                        <div className="d-flex flex-wrap align-items-center mb-1">
                                            <span className="custom-block-span">Ubicacion:</span>

                                            <p className="mb-0">{item.location}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        : 
                        <div className="col-lg-12 col-12">
                            <h3 className="mb-lg-5 mb-4">No se encontraron eventos previos.</h3>
                        </div>
                    }
                </div>
            </div>
        </section>
    );
};

export default events;