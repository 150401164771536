import React, { useRef, forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TextEditor = forwardRef(({ onContentChange, html }, ref) => {
    const editorRef = useRef(null);
    const [isEditorReady, setIsEditorReady] = useState(false);
    const [isContentSet, setIsContentSet] = useState(false);

    const handleEditorChange = (content) => {
        if (onContentChange) {
            onContentChange(content);
        }
    };

    useImperativeHandle(ref, () => ({
        getContent: () => {
            if (editorRef.current) {
                return editorRef.current.getContent();
            }
            return '';
        },
        setContent: (content) => {
            if (editorRef.current) {
                editorRef.current.setContent(content);
            }
        },
    }));

    const init = {
        height: 250,
        min_height: 250,
        menubar: true,
        statusbar: true,
        plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
            'insertdatetime', 'media', 'table', 'help', 'wordcount'
        ],
        toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | fullscreen',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px, background-color:red }',

    };

    useEffect(() => {
        if (editorRef.current && isEditorReady && !isContentSet) {
            editorRef.current.setContent(html);
            setIsContentSet(true);
        }
    }, [isEditorReady, isContentSet, html]);

    return (
        <Editor
            apiKey={process.env.REACT_APP_TINY_API_KEY}
            onInit={(evt, editor) => {
                editorRef.current = editor;
                setIsEditorReady(true);
            }}
            init={init}
            onEditorChange={handleEditorChange}
        />
    );
});

export default TextEditor;