import React, { useState } from 'react';
import axios from 'axios';
import Modal from '../utility/Modal.jsx';

const LoginModal = () => {
    const [error, setError] = useState(''); 
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });


    const handleChange = event => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleLogin = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_ENDPOINT}/server/api/v1/login/index.php`,
                { ...formData },
                { params: { action: 'loginUser' } }
            );
            if (response.data.success) {
                const expirationTime = new Date().getTime() + 12 * 60 * 60 * 1000; // 12 hs expiration time
                localStorage.setItem('session_token', response.data.session_token);
                localStorage.setItem('session_token_expiration', expirationTime);
                window.location.reload();
            } else {
                throw new Error(response.data.message)
            }
        } catch (error) {
            setError(error.message ? error.message : 'Error en la conexión a la base de datos');
            console.error('Error al iniciar sesión:', error);
        }
    };

    return (
        <div>
            <Modal
                title="Iniciar Sesión"
                onClose={() => { setError() }}
                onConfirm={handleLogin}
                id="loginModal"
                label="loginModalLabel"
            >
                <form className="custom-form contact-form">
                    <input
                        type="text"
                        className="form-control"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        placeholder="Usuario"
                    />
                    <input
                        type="password"
                        className="form-control"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        placeholder="Contraseña"
                    />
                </form>
                {
                    error ? 
                        <div className="form-error">
                            {error}
                        </div>
                        : ''
                }
            </Modal>
        </div>
    );
}

export default LoginModal;
