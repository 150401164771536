import CreateUserModal from "./CreateUserModal";
import LoginModal from "./LoginModal";

const Footer = ({userVerification}) => {

    const handleLogout = () => {
        localStorage.removeItem('session_token');
        localStorage.removeItem('session_token_expiration');
        window.location.href = '/';
    };

    return (
        <footer className="site-footer section-padding mt-auto">
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 col-lg-6 col-12 me-auto mb-5 mb-lg-0">
                        <a className="navbar-brand d-flex align-items-center" href="/">
                            <img src="/images/logos/logo.svg" className="navbar-brand-image img-fluid" alt="Sofia Ciliano Educación Canina"/>
                        </a>
                        <p className="copyright-text pt-3">
                            {
                                userVerification ? 
                                    'Copyright ©' :
                                    <button className="copyright-text hide-login" data-bs-toggle="modal" data-bs-target="#loginModal">
                                        Copyright © 
                                    </button>

                            }
                            {new Date().getFullYear()} Sofia Ciliano Educación Canina
                            </p>
                        {
                            userVerification && (
                                <div>
                                    <button type="button" className="btn custom-btn" onClick={handleLogout}>
                                        Cerrar Sesion
                                    </button>
                                    <button type="button" className="btn custom-btn custom-border-btn ms-3" data-bs-toggle="modal" data-bs-target="#createUserModal">
                                        Registrar Usuario
                                    </button>
                                </div>
                            )
                        }
                    </div>

                    <div className="col-sm-4 col-md-4 col-12 text-center">
                        <ul className="social-icon mb-4">
                            <li className="social-icon-item">
                                <a href="https://www.instagram.com/sofiacilianoeducanina/" target="_blank" rel="noreferrer">
                                    <i className="social-icon-link bi-instagram"/>
                                </a>
                            </li>

                            <li className="social-icon-item">
                                <a href="https://www.facebook.com/sofiacilianoeducanina" target="_blank" rel="noreferrer">
                                    <i className="social-icon-link bi-facebook"/>
                                </a>
                            </li>

                            <li className="social-icon-item">
                                <a href="https://wa.me/59899932721" target="_blank" rel="noreferrer">
                                    <i className="social-icon-link bi-whatsapp"/>
                                </a>
                            </li>

                            <li className="social-icon-item">
                                <a href="https://www.linkedin.com/company/sof%C3%ADa-ciliano-educaci%C3%B3n-canina/" target="_blank" rel="noreferrer">
                                    <i className="social-icon-link bi-linkedin"/>
                                </a>
                            </li>
                        </ul>
                        <p className="copyright-text">Diseñado por <a rel="nofollow noreferrer" href="https://agustintoth.xyz" target="_blank">Agustin Toth</a></p>
                        
                    </div>

                </div>
            </div>
            <LoginModal />
            { userVerification ? <CreateUserModal /> : ''}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#525647" fillOpacity="1" d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>
        </footer>
    );
};

export default Footer;