import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Dashboard from './Dashboard';
import EventsList from './pages/EventsList';
import EventDetails from './pages/EventDetails';
import useUserVerification from "../js/useUserVerification"

const Main = () => {
    const isValidUser = useUserVerification();

    const isSessionTokenValid = () => {
        const expirationTime = localStorage.getItem('session_token_expiration');
        return expirationTime && new Date().getTime() < parseInt(expirationTime);
    };
    if (!isSessionTokenValid()) {
        localStorage.removeItem('session_token');
        localStorage.removeItem('session_token_expiration');
    }

    return(
        <Router>
            <Routes>
                <Route path="*" element={<EventDetails userVerification={isValidUser} />} />
                <Route path="/" element={<Dashboard userVerification={isValidUser}/>} />
                <Route path="/eventos" element={<EventsList userVerification={isValidUser}/>} />
                <Route path="/evento/:name/:id" element={<EventDetails userVerification={isValidUser} mode="view"/>} />
                <Route path="/evento/editar/:name/:id" element={<EventDetails userVerification={isValidUser} mode="update"/>} />
                <Route path="/evento/crear-evento" element={<EventDetails userVerification={isValidUser} mode="create"/>} />
            </Routes>
        </Router>
    );
}
export default Main;