import React from 'react';

const ErrorMessages = ({ field, inputValidations, errors }) => {
  if (!errors[field]) {
    return null;
  }

  const fieldErrors = errors[field];
  const fieldValidations = inputValidations[field].validations;

  return (
    <div>
      {Object.keys(fieldValidations).map(validationType => {
        if (fieldErrors.type === validationType) {
          return (
            <p key={validationType} className="error">
              {fieldValidations[validationType].message}
            </p>
          );
        }
        return null;
      })}
    </div>
  );
};

export default ErrorMessages;