const Modal = ({ title, children, onClose, onConfirm, id, label }) => {
    return (
        <div className="modal fade" id={id} tabIndex="-1" aria-labelledby={label} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content rounded-5">
                    <div className="modal-header border-0 px-4">
                        <h5 className="modal-title" id={label}>{title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    <div className="modal-footer border-0">
                        <button type="button" className="btn custom-btn custom-border-btn" data-bs-dismiss="modal" onClick={onClose}>Cerrar</button>
                        <button type="button" className="btn custom-btn" onClick={onConfirm}>Confirmar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;