import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import TextEditor from './TextEditor';
import InputWithValidation from './InputWithValidation';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { jsonExportFromString } from '../../js/utility'

const Form = ({ action }) => {
    const { id } = useParams();
    const [customImageName, setCustomImageName] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [evento, setEvento] = useState({
        title: '',
        image: '',
        about: '',
        objetives: '',
        planification: '',
        including: '',
        important: '',
        date: '',
        location: '',
        people: '',
        cost: '',
        soldOut: false,
    });

    const handleEditorChange = (content, editorName) => {
        setEvento(prevContents => ({
            ...prevContents,
            [editorName]: content,
        }));
    };

    const handleChange = event => {
        const { name, value, type } = event.target;
        if (type === 'file') {
            const file = event.target.files[0];
            const customName = evento.title ? `${evento.title}.jpg` : file.name;
            setCustomImageName(customName);
            setEvento(prevEvento => ({
                ...prevEvento,
                [name]: file,
            }));
        } else {
            if (name === 'title' && customImageName && evento.image) {
                const updatedCustomName = `${value}.jpg`;
                setCustomImageName(updatedCustomName);
            }
            setEvento(prevEvento => ({
                ...prevEvento,
                [name]: value,
            }));
        }
    };

    const form = useRef();
    const [formSent, setFormSent] = useState({success: false, error: false});

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: evento,
    });
    

    const textEditorValidations = {
        about: {
            label: 'Sobre el curso',
            validations: {
                required: true,
                maxLength: { value: 1000, message: 'El texto no debe exceder los 1000 caracteres.' },
            }
        },
        objetives: {
            label: 'Objetivos',
            validations: {
                required: true,
                maxLength: { value: 1000, message: 'El texto no debe exceder los 1000 caracteres.' },
            }
        },
        planification: {
            label: 'Planificacion',
            validations: {
                required: true,
                maxLength: { value: 5000, message: 'El texto no debe exceder los 5000 caracteres.' },
            }
        },
        including: {
            label: 'Que incluye',
            validations: {
                required: true,
                maxLength: { value: 750, message: 'El texto no debe exceder los 750 caracteres.' },
            }
        },
        important: {
            label: 'Importante',
            validations: {
                required: true,
                maxLength: { value: 250, message: 'El texto no debe exceder los 250 caracteres.' },
            }
        },
    };

    const inputValidations = {
        title: {
            label: 'Titulo',
            validations: {
                required: { value: true, message: 'Titulo requerido.' },
                maxLength: { value: 50, message: 'El texto no debe exceder los 50 caracteres.' },
            }
        },
        image: {
            label: 'Imagen',
            validations: {
                required: { value: evento.image ? false : true, message: 'Imagen requerida.' },
            }
        },
        date: {
            label: 'Fecha de realizacion',
            validations: {
                required: { value: true, message: 'Fecha de realizacion requerida.' },
            }
        },
        location: {
            label: 'Ubicacion',
            validations: {
                required: { value: true, message: 'Ubicacion requerida.' },
                maxLength: { value: 75, message: 'El texto no debe exceder los 75 caracteres.' },
            }
        },
        people: {
            label: 'Cupos',
            validations: {
                required: { value: true, message: 'Cupos requeridos.' },
                maxLength: { value: 4, message: 'El cupo no puede superar las 9,999 personas.' },
            }
        },
        cost: {
            label: 'Precio',
            validations: {
                required: { value: true, message: 'Precio requerido.' },
                maxLength: { value: 6, message: 'El costo no puese ser mayor a $999.999.' },
            }
        },
        soldOut: {
            label: 'Agotado',
        },
    };

    const onSubmit = async () => {
        const formData = new FormData();
        Object.entries(evento).forEach(([key, value]) => {
            // if (!value && key !== 'soldOut') // ACA VAMOS A HACER LA VALIDACION DEL FRONT DESPUES
            if (key === 'image' && typeof(value) !== 'string') {
                formData.append(key, value, customImageName);
            } else {
                formData.append(key, value);
            }
        });
        const url = `${process.env.REACT_APP_API_ENDPOINT}/server/api/v1/eventos/index.php${action === "update" ? '/?id='+id : ''}`;
        try {
            const res = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const json = jsonExportFromString(res.data);
            if (json.error) {
                setError(json.message);
                throw new Error(error);
            }
            setFormSent({ success: true, error: false });
            setLoading(true);
            setTimeout(() => {                
                const redirectTo = action === 'update'
                  ? `/evento/${evento.title}/${id}`
                  : `/evento/${evento.title}/${json.eventId}`;
                
                window.location.href = redirectTo;
            }, 5000);
        } catch (err) {
            console.error(err);
            setFormSent({ success: false, error: true });
        }
    };

    useEffect(() => {
        if(action === 'update') {
            const url = `${process.env.REACT_APP_API_ENDPOINT}/server/api/v1/eventos/index.php?id=${id}`;
            const fetchData = async () => {            
                try {
                    const response = await axios.get(url);
                    const data = response.data;
                    const eventoData = data.find(evento => evento.id === parseInt(id));
                    setEvento(eventoData);
                    Object.keys(eventoData).forEach(key => {
                        setValue(key, eventoData[key]);
                    });
                } catch (error) {
                    throw new Error(error);
                }
            };
            fetchData();
        }
    }, [id, action, setValue]);

    return (
        <div className="container">
            <div className="col-md-12">
                <div className="panel panel-primary">
                    <div className="panel-body text-center">
                        <div className='d-flex justify-content-center mt-4'>
                            {
                                formSent.success &&
                                !formSent.error && 
                                <div className="form-success">
                                    {
                                        action === 'update' ?
                                            'Su curso ha sido modificado con exito!'
                                            : 'Su curso ha sido creado con exito!'
                                    }
                                    <br />
                                    Por favor espere que sera redireccionado enseguida.
                                </div>
                            }
                            { !formSent.success && formSent.error && <div className="form-error">{error}</div> }
                        </div>
                        {
                            loading ? 
                            <div>
                                <img src="/images/loading.svg" width="25%" alt="loading"/>
                            </div>
                            :
                            <form ref={form} onSubmit={handleSubmit(onSubmit)} className="custom-form contact-form">
                                

                                <div className="row">
                                    <div className="col-lg-12 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-8 d-inline-block">
                                                <InputWithValidation
                                                    type="text"
                                                    name="title"
                                                    placeholder={inputValidations.title.label}
                                                    className={errors.title ? 'form-control mb-0 is-invalid' : 'form-control'}
                                                    register={register}
                                                    validation={inputValidations.title.validations}
                                                    value={evento.title}
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    inputValidations={inputValidations}
                                                />
                                            </div>
                                            <div className="col-12 col-md-4 d-inline-block">
                                                <label htmlFor="soldOut" className='form-control checkbox'>
                                                    <input
                                                        type="checkbox"
                                                        id="soldOut"
                                                        name="soldOut"
                                                        className="custom-checkbox"
                                                        checked={evento.soldOut}
                                                        onChange={(e) => {
                                                            const newValue = e.target.checked;
                                                            handleChange({target: {name: 'soldOut', value: newValue, type: 'checkbox'}});
                                                        }}
                                                    />
                                                    {inputValidations.soldOut.label}
                                                </label>
                                            </div>
                                        </div>
                                        <InputWithValidation
                                            type="file"
                                            name="image"
                                            placeholder={inputValidations.image.label}
                                            className={errors.image ? 'form-control mb-0 is-invalid' : 'form-control'}
                                            register={register}
                                            validation={inputValidations.image.validations}
                                            onChange={handleChange}
                                            errors={errors}
                                            inputValidations={inputValidations}
                                        />
                                        {Object.keys(textEditorValidations).map(fieldName => (
                                            <div className="form-floating my-4 rounded-5" key={fieldName}>
                                                <TextEditor
                                                    html={evento[fieldName]}
                                                    onContentChange={content => handleEditorChange(content, fieldName)}
                                                    className={errors[fieldName] ? 'form-control mb-0 pt-5 is-invalid' : 'form-control'}
                                                    {...register(fieldName, textEditorValidations[fieldName])}
                                                />
                                                {errors[fieldName] && <p className="error">{errors[fieldName].message}</p>}
                                                <label htmlFor="floatingTextarea">{textEditorValidations[fieldName].label}</label>
                                            </div>
                                        ))}

                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <InputWithValidation
                                            type="date"
                                            name="date"
                                            placeholder={inputValidations.date.label}
                                            className={errors.date ? 'form-control mb-0 is-invalid' : 'form-control'}
                                            register={register}
                                            validation={inputValidations.date.validations}
                                            value={evento.date}
                                            onChange={handleChange}
                                            errors={errors}
                                            inputValidations={inputValidations}
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <InputWithValidation
                                            type="text"
                                            name="location"
                                            placeholder={inputValidations.location.label}
                                            className={errors.location ? 'form-control mb-0 is-invalid' : 'form-control'}
                                            register={register}
                                            validation={inputValidations.location.validations}
                                            value={evento.location}
                                            onChange={handleChange}
                                            errors={errors}
                                            inputValidations={inputValidations}
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <InputWithValidation
                                            type="number"
                                            name="people"
                                            placeholder={inputValidations.people.label}
                                            className={errors.people ? 'form-control mb-0 is-invalid' : 'form-control'}
                                            register={register}
                                            validation={inputValidations.people.validations}
                                            value={evento.people}
                                            onChange={handleChange}
                                            errors={errors}
                                            inputValidations={inputValidations}
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <InputWithValidation
                                            type="number"
                                            name="cost"
                                            placeholder={inputValidations.cost.label}
                                            className={errors.cost ? 'form-control mb-0 is-invalid' : 'form-control'}
                                            register={register}
                                            validation={inputValidations.cost.validations}
                                            value={evento.cost}
                                            onChange={handleChange}
                                            errors={errors}
                                            inputValidations={inputValidations}
                                        />
                                    </div>
                                    <div className="col-lg-12 col-12">
                                        <button name="submit" type="submit" className="form-control" title="Send Message">{ action === "update" ? "Editar" : "Crear"} Evento</button>
                                    </div>
                                    { !formSent.success && formSent.error && <div className="form-error">{error}</div> }
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Form;
