import React from 'react';
import ErrorMessages from './ErrorMessages'; // Ajusta la ruta a tu componente de mensajes de error

const InputWithValidation = ({
    type,
    name,
    placeholder,
    className,
    register,
    validation,
    value,
    onChange,
    errors,
    inputValidations,
}) => {
    return (
        <div className="form-floating">
            <input
                type={type}
                name={name}
                id={name}
                placeholder={placeholder}
                className={className}
                {...register(name, validation)}
                value={value}
                onChange={onChange}
            />
            <ErrorMessages field={name} inputValidations={inputValidations} errors={errors} />
            <label htmlFor="floatingTextarea">{placeholder}</label>
        </div>
    );
};

export default InputWithValidation;
