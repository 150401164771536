const monthNames = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

export const getFormatedDate = (date, format) => {
    const day = new Date(date).getDate() + 1;
    const month = monthNames[new Date(date).getMonth()];
    const year = new Date(date).getFullYear();

    switch(format) {
        case 'day': return day;
        case 'month': return month;
        case 'year': return year;
        default: return `${day} ${month} ${year}`;
    }
}

export const jsonExportFromString = (string) => {
    if (typeof string !== 'string') string = JSON.stringify(string);
    const startIndex = string.indexOf('{');
    const jsonString = string.substr(startIndex);
    try {
        const jsonData = JSON.parse(jsonString);
        return jsonData;
    } catch (e) {
        return "Error al analizar el JSON";
    }
}