import Header from '../content/Header';
import List from '../content/List';
import Footer from '../content/Footer';
import BackToTopButton from '../utility/BackToTheTopButton';

const EventsList = ({userVerification}) => {
    return (
        <main className="d-flex flex-column">
            <Header />

            <section className="hero-section eventos hero-50 d-flex justify-content-center align-items-center" id="section_1">

                <div className="section-overlay"></div>

                <svg viewBox="0 0 1962 178" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path fill="#7F8442" d="M0 114C118.5 114 118.5 167 237 167L237 0L0 0Z" strokeWidth="0" /><path fill="#7F8442" d="M236 167C373 167 373 128 510 128L510 0L236 0Z" strokeWidth="0" /><path fill="#7F8442" d="M509 128C607 128 607 153 705 153L705 0L509 0Z" strokeWidth="0" /><path fill="#7F8442" d="M704 153C812 153 812 113 920 113L920 0L704 0Z" strokeWidth="0" /><path fill="#7F8442" d="M919 113C1048.5 113 1048.5 148 1178 148L1178 0L919 0Z" strokeWidth="0" /><path fill="#7F8442" d="M1177 148C1359.5 148 1359.5 129 1542 129L1542 0L1177 0Z" strokeWidth="0" /><path fill="#7F8442" d="M1541 129C1751.5 129 1751.5 138 1962 138L1962 0L1541 0Z" strokeWidth="0" /></svg>

                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-12">

                            <h1 className="text-white mb-4 pb-2">Lista de Eventos</h1>

                            <div className="custom-btn-group">
                                <a href="#próximos-eventos" className="btn custom-btn smoothscroll me-3">Ver próximos eventos</a>
                                {
                                    userVerification ?
                                        <a href="/evento/crear-evento" className="link smoothscroll me-3">Crear evento nuevo</a>
                                    : ''
                                }
                            </div>
                        </div>


                    </div>
                </div>

                <svg viewBox="0 0 1962 178" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path fill="rgba(241, 244, 222, 1)" d="M0 114C118.5 114 118.5 167 237 167L237 0L0 0Z" strokeWidth="0" /><path fill="rgba(241, 244, 222, 1)" d="M236 167C373 167 373 128 510 128L510 0L236 0Z" strokeWidth="0" /><path fill="rgba(241, 244, 222, 1)" d="M509 128C607 128 607 153 705 153L705 0L509 0Z" strokeWidth="0" /><path fill="rgba(241, 244, 222, 1)" d="M704 153C812 153 812 113 920 113L920 0L704 0Z" strokeWidth="0" /><path fill="rgba(241, 244, 222, 1)" d="M919 113C1048.5 113 1048.5 148 1178 148L1178 0L919 0Z" strokeWidth="0" /><path fill="rgba(241, 244, 222, 1)" d="M1177 148C1359.5 148 1359.5 129 1542 129L1542 0L1177 0Z" strokeWidth="0" /><path fill="rgba(241, 244, 222, 1)" d="M1541 129C1751.5 129 1751.5 138 1962 138L1962 0L1541 0Z" strokeWidth="0" /></svg>
            </section>

            <List page="eventList"/>
            
            <Footer userVerification={userVerification}/>

            <BackToTopButton />

        </main>
    );
};

export default EventsList;