import Header from './content/Header';
import Home from './content/Home';
import About from './content/About';
import Services from './content/Services';
import Gallery from './content/Gallery';
import List from './content/List';
import Contact from './content/Contact';
import Footer from './content/Footer';
import BackToTopButton from './utility/BackToTheTopButton';


const Dashboard = ({userVerification}) => {    
    return (
        <main className="d-flex flex-column">
            <Header />

            <Home />

            <About />

            <Services />

            <Gallery />

            <List page="homepage" />

            <Contact />

            <Footer userVerification={userVerification} />

            <BackToTopButton />
        </main>
    );
};

export default Dashboard;