const Services = () => {
    return (
        <section className="section-bg-image" id="servicios">
            <svg viewBox="0 0 1265 144" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path fill="rgba(255, 255, 255, 1)" d="M 0 40 C 164 40 164 20 328 20 L 328 20 L 328 0 L 0 0 Z" strokeWidth="0"/><path fill="rgba(255, 255, 255, 1)" d="M 327 20 C 445.5 20 445.5 89 564 89 L 564 89 L 564 0 L 327 0 Z" strokeWidth="0"/><path fill="rgba(255, 255, 255, 1)" d="M 563 89 C 724.5 89 724.5 48 886 48 L 886 48 L 886 0 L 563 0 Z" strokeWidth="0"/><path fill="rgba(255, 255, 255, 1)" d="M 885 48 C 1006.5 48 1006.5 67 1128 67 L 1128 67 L 1128 0 L 885 0 Z" strokeWidth="0"/><path fill="rgba(255, 255, 255, 1)" d="M 1127 67 C 1196 67 1196 0 1265 0 L 1265 0 L 1265 0 L 1127 0 Z" strokeWidth="0"/></svg>
            <div className="container">
                <h1 className="text-white text-center pb-3">Servicios que ofrezco</h1>
                <div className="row">
                    <div className="col-lg-6 col-12 d-flex">
                        <div className="section-bg-image-block mt-4 flex-fill">
                            <h2 className="mb-lg-3">Sesiones de educación canina</h2>
                            <p>Encuentros tanto en línea como presenciales (a domicilio), en los cuales abordamos de manera personalizada todos los temas que deseas mejorar en relación a la convivencia con tu perro.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 d-flex">
                        <div className="section-bg-image-block mt-4 flex-fill">
                            <h2 className="mb-3">Talleres de convivencia y socialización</h2>
                            <p>Vive una jornada llena de juegos y aprendizaje junto a tu perro. Apto para todas las edades.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 d-flex">
                        <div className="section-bg-image-block mt-4 flex-fill">
                            <h2 className="mb-lg-3">Curso para cachorros</h2>
                            <p>Participa en nuestros encuentros grupales donde aprenderás desde cero cómo educar a tu cachorro.</p>
                            <p>Diseñado para perros de 3 a 9 meses de edad.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 d-flex">
                        <div className="section-bg-image-block mt-4 flex-fill">
                            <h2 className="mb-3">Eventos y charlas</h2>
                            <p>Diseñado para veterinarias y otras empresas.</p>
                            <p>Contame tu idea y la llevamos adelante!</p>
                        </div>
                    </div>
                </div>
            </div>

            <svg viewBox="0 0 1265 144" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path fill="rgba(255, 255, 255, 1)" d="M 0 40 C 164 40 164 20 328 20 L 328 20 L 328 0 L 0 0 Z" strokeWidth="0"/><path fill="rgba(255, 255, 255, 1)" d="M 327 20 C 445.5 20 445.5 89 564 89 L 564 89 L 564 0 L 327 0 Z" strokeWidth="0"/><path fill="rgba(255, 255, 255, 1)" d="M 563 89 C 724.5 89 724.5 48 886 48 L 886 48 L 886 0 L 563 0 Z" strokeWidth="0"/><path fill="rgba(255, 255, 255, 1)" d="M 885 48 C 1006.5 48 1006.5 67 1128 67 L 1128 67 L 1128 0 L 885 0 Z" strokeWidth="0"/><path fill="rgba(255, 255, 255, 1)" d="M 1127 67 C 1196 67 1196 0 1265 0 L 1265 0 L 1265 0 L 1127 0 Z" strokeWidth="0"/></svg>
        </section>
    );
};

export default Services;