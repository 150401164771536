/* eslint-disable jsx-a11y/no-redundant-roles */


import 'bootstrap/dist/css/bootstrap.css';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';

const Contact = () => {

    const form = useRef();
    const [formSent, setFormSent] = useState({success: false, error: false});

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = () => {
        if (!process.env.REACT_APP_EMAILJS_SERVICE || !process.env.REACT_APP_EMAILJS_TEMPLATE || !process.env.REACT_APP_EMAILJS_PUBLIC_KEY) return setFormSent({success: false, error: true});
        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE,
            process.env.REACT_APP_EMAILJS_TEMPLATE,
            form.current,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then(() => {
            setFormSent({success: true, error: false});
        }, () => {
            setFormSent({success: false, error: true});
        });
    };

    return (
        <section className="contact-section" id="contacto">
            <svg viewBox="0 0 1265 144" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path fill="rgba(241, 244, 222, 1)" d="M 0 40 C 164 40 164 20 328 20 L 328 20 L 328 0 L 0 0 Z" strokeWidth="0"></path><path fill="rgba(241, 244, 222, 1)" d="M 327 20 C 445.5 20 445.5 89 564 89 L 564 89 L 564 0 L 327 0 Z" strokeWidth="0"></path><path fill="rgba(241, 244, 222, 1)" d="M 563 89 C 724.5 89 724.5 48 886 48 L 886 48 L 886 0 L 563 0 Z" strokeWidth="0"></path><path fill="rgba(241, 244, 222, 1)" d="M 885 48 C 1006.5 48 1006.5 67 1128 67 L 1128 67 L 1128 0 L 885 0 Z" strokeWidth="0"></path><path fill="rgba(241, 244, 222, 1)" d="M 1127 67 C 1196 67 1196 0 1265 0 L 1265 0 L 1265 0 L 1127 0 Z" strokeWidth="0"></path></svg>

                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5 col-12">
                            <form ref={form} onSubmit={handleSubmit(onSubmit)} className="custom-form contact-form">
                                <h2 className="mb-4 pb-2">Contactame!</h2>
                                <div className='d-flex justify-content-center'>
                                {
                                    formSent.success &&
                                    !formSent.error && 
                                    <div className="form-success">
                                        Mensaje enviado exitosamente. Me pondré en contacto contigo a la brevedad.
                                    </div>}
                                {
                                    !formSent.success &&
                                    formSent.error && 
                                    <div className="form-error">
                                        Se ha producido un error. Por favor, intenta nuevamente. <br/>
                                        Si el problema persiste, inténtalo de nuevo más tarde.
                                    </div>
                                }
                                </div>


                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="form-floating">
                                            
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className={errors.name ? 'form-control mb-0 is-invalid' : 'form-control' }
                                                placeholder="Nombre"
                                                {...register(
                                                    'name',
                                                    {
                                                        required: true,
                                                        pattern: /^[A-Za-z_ ]+$/i,
                                                        maxLength: 30
                                                    }
                                                )}
                                            />
                                            {errors.name?.type === 'required' && <p className="error">Nombre requerido.</p>}
                                            {errors.name?.type === 'pattern' && <p className="error">El nombre no puede contener numeros.</p>}
                                            {errors.name?.type === 'maxLength' && <p className="error">El largo del nombre debe ser de 30 caracteres o menos.</p>}


                                            <label htmlFor="floatingInput">Nombre</label>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-12"> 
                                        <div className="form-floating">
                                            <input
                                                type="number"
                                                name="phone"
                                                id="phone"
                                                className={errors.phone ? 'form-control mb-0 is-invalid' : 'form-control' }
                                                placeholder="Teléfono"
                                                {...register(
                                                    'phone',
                                                    { 
                                                        required: true,
                                                        pattern: /^(0\d{8}|[1-9]\d{0,7})$/,
                                                        maxLength: 9
                                                    }
                                                )}
                                            />
                                            {errors.phone?.type === 'required' && <p className="error">Teléfono requerido.</p>}
                                            {errors.phone?.type === 'pattern' && <p className="error">Ingresa un teléfono válido.</p>}
                                            {errors.phone?.type === 'maxLength' && <p className="error">El teléfono no puede contener mas de 9 caracteres.</p>}
                                        
                                            <label htmlFor="floatingInput">Teléfono</label>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-12">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                name="subject"
                                                id="subject"
                                                placeholder="Asunto"
                                                className={errors.subject ? 'form-control mb-0 is-invalid' : 'form-control' }
                                                {...register(
                                                    'subject',
                                                    {
                                                        required: true,
                                                        maxLength: 35
                                                    }
                                                )}
                                            />
                                            {errors.subject?.type === 'required' && <p className="error">Asunto requerido.</p>}
                                            {errors.subject?.type === 'maxLength' && <p className="error">El largo del asunto debe ser de 35 caracteres o menos.</p>}

                                            <label htmlFor="floatingTextarea">Asunto</label>
                                        </div>
                                        <div className="form-floating">
                                            <textarea 
                                                name="message"
                                                id="message"
                                                rows="5"
                                                className={errors.message ? 'form-control mb-0 is-invalid' : 'form-control' }
                                                placeholder="Mensaje"
                                                {...register(
                                                    'message',
                                                    {
                                                        required: true,
                                                        maxLength: 255
                                                    }
                                                )}
                                            ></textarea>
                                            {errors.message?.type === 'required' && <p className="error">Mensaje requerido.</p>}
                                            {errors.message?.type === 'maxLength' && <p className="error">El largo del mensaje debe ser de 255 caracteres o menos.</p>}
                                            <label htmlFor="floatingTextarea">Mensaje</label>
                                        </div>
                                        <button name="submit" type="submit" className="form-control" title="Send Message">Enviar</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="col-lg-6 col-12">
                            <div className="contact-info mt-5 text-center">
                                <div className="contact-info-item w-auto">
                                    <div className="contact-info-body">

                                        <p className="mt-2 mb-1">
                                            <i className="bi-whatsapp text-white me-2"/>
                                            <a href="https://wa.me/59899932721" target="_blank" className="contact-link" rel="noreferrer">
                                            099 932 721
                                            </a>
                                        </p>

                                        <p className="mb-0 d-flex justify-content-center">
                                            <i className="bi-envelope-fill text-white me-2"/>
                                            <a href="mailto:educacioncanina@sofiaciliano.com.uy" target="_blank" className="contact-link" rel="noreferrer">
                                                educacioncanina@sofiaciliano.com.uy
                                            </a>
                                        </p>
                                    </div>

                                    <div className="contact-info-footer text-white">
                                            <strong>Ciudad de la Costa, Uruguay</strong>
                                            <br />
                                            <strong>Montevideo, Uruguay</strong>
                                    </div>
                                </div>

                                <img src="/images/perro.svg" className="img-fluid" width="500" alt=""/>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
    );
};

export default Contact;