import { useState, useEffect } from 'react';
import axios from 'axios';

const useUserVerification = () => {
    const [isValidUser, setIsValidUser] = useState(false);

    useEffect(() => {
        const checkUserValidity = async () => {
            try {
                if (!localStorage.getItem('session_token')) return setIsValidUser(false);	
                const response = await axios.get(
                    `${process.env.REACT_APP_API_ENDPOINT}/server/api/v1/login/index.php`,
                    {
                        params: { action: 'verifyUser' },
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('session_token')}`
                        }
                    });

                if (response.data.success) {
                    setIsValidUser(true);
                } else {
                    setIsValidUser(false);
                    localStorage.removeItem('session_token');
                    localStorage.removeItem('session_token_expiration');
                    throw new Error (response.data.message)
                }
            } catch (error) {
                console.error(error.message);
                setIsValidUser(false);
                localStorage.removeItem('session_token');
                localStorage.removeItem('session_token_expiration');
            }
        };

        checkUserValidity();
    }, []);

    return isValidUser;
};

export default useUserVerification;