const Gallery = () => {
    const galleryImages = [];
    for (let i = 1; i <= 12; i++) {
        galleryImages.push(`${i}.1.jpg`)
    }
    return (
        <section className="section-padding" id="galeria">
            <div className="container">
                <div className="row mt-5">
                {galleryImages.map((imageName, index) => (
                    <div className="col-lg-4 col-md-12 mb-4 mb-lg-0" key={index}>
                        <div className="block mb-4">
                            <div className="block-image-wrap d-flex justify-content-center">
                                <div className="image-container">
                                    <img
                                    src={`images/gallery/${imageName}`}
                                    className="w-100 h-100 shadow-1-strong rounded block-image"
                                    alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            </div>
        </section>
    );
};

export default Gallery;