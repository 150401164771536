import React from 'react';
import Form from '../utility/Form';
import NotFound from '../content/NotFound';
import { useParams } from 'react-router-dom';


const Update = ({userVerification}) => {
    const { name, id } = useParams();

    const handleBack = () => {
        const confirmed = window.confirm("Todos los cambios que hiciste se perderan, ¿Estás seguro que quieres volver al evento sin guardar?");
        if (!confirmed) return;
        window.location.href = (`/evento/${name}/${id}`);
    }
    if (!userVerification) return <NotFound />;
    return (
        <div>
            <section
                id="section_1"
                className="hero-section hero-50 d-flex justify-content-center align-items-center"
                style={{ backgroundImage: `url('${process.env.REACT_APP_API_ENDPOINT}/images/eventos/${name}.jpg')` }}
            >

                <div className="section-overlay" />

                <svg viewBox="0 0 1962 178" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path fill="#7F8442" d="M 0 114 C 118.5 114 118.5 167 237 167 L 237 167 L 237 0 L 0 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 236 167 C 373 167 373 128 510 128 L 510 128 L 510 0 L 236 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 509 128 C 607 128 607 153 705 153 L 705 153 L 705 0 L 509 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 704 153 C 812 153 812 113 920 113 L 920 113 L 920 0 L 704 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 919 113 C 1048.5 113 1048.5 148 1178 148 L 1178 148 L 1178 0 L 919 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 1177 148 C 1359.5 148 1359.5 129 1542 129 L 1542 129 L 1542 0 L 1177 0 Z" strokeWidth="0"/><path fill="#7F8442" d="M 1541 129 C 1751.5 129 1751.5 138 1962 138 L 1962 138 L 1962 0 L 1541 0 Z" strokeWidth="0"/></svg>

                <div className="container">
                    <div className="row">

                        <div className="col-12">

                            <h1 className="text-white display-3 fw-bolder">Editando</h1>
                            <h2 className="text-white mb-4 pb-2"><em>{name}</em></h2>
                            <button onClick={handleBack} className="btn custom-btn smoothscroll me-3">Volver al evento</button>
                        </div>

                    </div>
                </div>

                <svg viewBox="0 0 1962 178" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path fill="#ffffff" d="M 0 114 C 118.5 114 118.5 167 237 167 L 237 167 L 237 0 L 0 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 236 167 C 373 167 373 128 510 128 L 510 128 L 510 0 L 236 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 509 128 C 607 128 607 153 705 153 L 705 153 L 705 0 L 509 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 704 153 C 812 153 812 113 920 113 L 920 113 L 920 0 L 704 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 919 113 C 1048.5 113 1048.5 148 1178 148 L 1178 148 L 1178 0 L 919 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 1177 148 C 1359.5 148 1359.5 129 1542 129 L 1542 129 L 1542 0 L 1177 0 Z" strokeWidth="0"/><path fill="#ffffff" d="M 1541 129 C 1751.5 129 1751.5 138 1962 138 L 1962 138 L 1962 0 L 1541 0 Z" strokeWidth="0"/></svg>
            </section>
            <section className="events-section events-detail-section section-padding" id="section_2">
                <div className="container">
                    <Form action="update"/>
                </div>
            </section>
        </div>
    );
};

export default Update;
