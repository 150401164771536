import UpcomingsEvents from './UpcomingEvents';
import LastestEvents from './LastestEvents';
import { useEffect, useState } from 'react';
import axios from 'axios';

const List = ({page}) => {
    const [latestEvents, setLatestEvents] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    useEffect(() => {
        const url = `${process.env.REACT_APP_API_ENDPOINT}/server/api/v1/eventos/index.php`;
        const fetchData = async () => {            
            try {
                const response = await axios.get(url);
                const eventoData = response.data;
                if (typeof eventoData !== 'object') throw new Error("Error al obtener datos");
                const currentDate = new Date();
                const latest = eventoData.filter(event => new Date(event.date) < currentDate);
                const upcoming = eventoData.filter(event => new Date(event.date) >= currentDate);
                setLatestEvents(latest);
                setUpcomingEvents(upcoming);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);
    if (page === 'homepage') {
        return (
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 255"><path fill="rgba(241, 244, 222, 1)" fillOpacity="1" d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>
                <UpcomingsEvents events={upcomingEvents} page={page} />
            </div>
        );
    } else if (page === 'eventList') {
        return (
            <div>
                <UpcomingsEvents events={upcomingEvents} page={page} />
                <svg viewBox="0 0 1265 144" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path fill="rgba(241, 244, 222, 1)" d="M 0 40 C 164 40 164 20 328 20 L 328 20 L 328 0 L 0 0 Z" strokeWidth="0"></path><path fill="rgba(241, 244, 222, 1)" d="M 327 20 C 445.5 20 445.5 89 564 89 L 564 89 L 564 0 L 327 0 Z" strokeWidth="0"></path><path fill="rgba(241, 244, 222, 1)" d="M 563 89 C 724.5 89 724.5 48 886 48 L 886 48 L 886 0 L 563 0 Z" strokeWidth="0"></path><path fill="rgba(241, 244, 222, 1)" d="M 885 48 C 1006.5 48 1006.5 67 1128 67 L 1128 67 L 1128 0 L 885 0 Z" strokeWidth="0"></path><path fill="rgba(241, 244, 222, 1)" d="M 1127 67 C 1196 67 1196 0 1265 0 L 1265 0 L 1265 0 L 1127 0 Z" strokeWidth="0"></path></svg>
                <LastestEvents events={latestEvents} />
            </div>
        );
    }
};

export default List;